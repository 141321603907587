<template>
  <section class="section">
    <h4 class="title has-text-centered is-4">Quest Stories</h4>
    <b-table
      :data="stories"
      :striped="true"
      :hoverable="true"
      :loading="loadingStories"
      class="mt-4 clickable-table user-table"
      @click="goToStory"
    >
      <b-table-column field="id" label="ID" v-slot="props">
        <p>
          {{ props.row.id }}
        </p>
      </b-table-column>

      <b-table-column field="title" label="Title" v-slot="props">
        <p>{{ props.row.title }}</p>
      </b-table-column>

      <b-table-column field="quests_count" label="Quests Count" v-slot="props">
        <p>{{ props.row.quests_count }}</p>
      </b-table-column>
    </b-table>

    <b-collapse :open="false" class="mt-6">
      <template #trigger>
        <b-button
          label="Create New Quest Story"
          icon-left="plus"
          type="is-info" />
      </template>
      <story-form></story-form>
    </b-collapse>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import StoryForm from '../../components/Quests/StoryForm.vue';

export default {
  name: 'Stories',
  components: { StoryForm },
  computed: {
    ...mapState('quest', ['loadingStories', 'stories']),
  },
  mounted() {
    this.loadStories();
  },
  methods: {
    loadStories() {
      this.$store.dispatch('quest/loadStories');
    },
    goToStory(row) {
      this.$router.push({ path: `/stories/${row.id}` });
    },
  },
};
</script>

<style>
.user-table {
  font-size: 12px;
}
</style>
